import { graphql, useStaticQuery } from "gatsby";
import { StrapiJoinOurTeamPage } from "../model/join-our-team-page";

export const useJoinOurTeamPage = (): StrapiJoinOurTeamPage => {
  const { strapiJoinOurTeamPage } = useStaticQuery(graphql`
    query useJoinOurTeamPage {
      strapiJoinOurTeamPage {
        title
        subtitle
        rightSideText
        leftSideText
        image {
          childImageSharp {
            gatsbyImageData(quality: 70, height: 400, width: 800)
          }
        }
        description
      }
    }
  `);

  return strapiJoinOurTeamPage;
};
