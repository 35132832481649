import React from "react";
import { JoinOurTeam } from "../components/JoinOurTeam/JoinOurTeam";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";

export interface IWantToBecomeAnAgentPageProps {
  className?: string;
}

export const IWantToBecomeAnAgentPage = (
  props: IWantToBecomeAnAgentPageProps
) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <JoinOurTeam />
    </Layout>
  );
};

export default IWantToBecomeAnAgentPage;
