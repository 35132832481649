import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { routes } from "../../lib/routes";
import { Button } from "../Shared/Button/Button";
import { Container } from "../Shared/Container/Container";
import { ContentRenderer } from "../Shared/ContentRenderer/ContentRenderer";
import { SectionHeader } from "../Shared/SectionHeader/SectionHeader";
import { useJoinOurTeamPage } from "./hooks/useJoinOurTeamPage";

export interface JoinOurTeamProps {
  className?: string;
}

export const JoinOurTeam = (props: JoinOurTeamProps) => {
  const { className = "" } = props;
  const {
    title,
    subtitle,
    description,
    image,
    leftSideText,
    rightSideText,
  } = useJoinOurTeamPage();

  return (
    <section className={`${className} py-16`}>
      <Container>
        <SectionHeader
          className="text-center"
          title={title}
          subtitle={subtitle}
          description={description}
        />
        <article className="mt-16 flex space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row flex-col">
          <div className="left lg:w-1/2">
            <div className="image shadow-lg flex">
              <GatsbyImage
                className="h-full w-full"
                image={getImage(image as any)}
                alt="tipper image"
              />
            </div>
            <div className="detail mt-12">
              <ContentRenderer source={leftSideText} />
              <div className="actions lg:mt-6 mt-12">
                <Link to={routes.contact.to}>
                  <Button>CHCEM SA STAŤ ČLENOM TÍMU</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="right lg:w-1/2 flex flex-col justify-between">
            <ContentRenderer source={rightSideText} />
          </div>
        </article>
      </Container>
    </section>
  );
};
